<template>
	<div class="wrapper">
		<div class="searchbox">
			<el-form :inline="true" :model="formInline" class="demo-form-inline">
				<el-form-item label="负责人">
					<el-input v-model="formInline.gridman" clearable placeholder="负责人"></el-input>
				</el-form-item>
				<el-form-item label="巡防人姓名">
					<el-input v-model="formInline.personname" clearable placeholder="负责人"></el-input>
				</el-form-item>
				<el-form-item label="巡防人身份证">
					<el-input v-model="formInline.personcard" clearable placeholder="负责人"></el-input>
				</el-form-item>
				<el-form-item label="巡防地址">
					<el-input v-model="formInline.houseaddress" clearable placeholder="巡防地址"></el-input>
				</el-form-item>
				<el-form-item label="告警状态">
					<el-select v-model="formInline.warnstatus" clearable placeholder="告警状态">
						<el-option v-for="opt in warnStatusOptions" :key="opt.value" :label="opt.label"
							:value="opt.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="完成状态">
					<el-select v-model="formInline.periodstatus" clearable placeholder="完成情况">
						<el-option v-for="opt in periodStatusOptions" :key="opt.value" :label="opt.label"
							:value="opt.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="search"><i class="el-icon-search"></i> 查询</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="contbox">
			<div class="leftcont">
				<el-table :data="tableData" v-loading="loading" :header-cell-style="{  fontSize: '14px', color: '#282828'}"
					:row-style="{ fontSize: '12px', color: '#6a6a6a'}"  border style="width: 100%">
					
					<el-table-column align="center" prop="alarm_status" label="告警状态"
						:formatter="(row) => formatterStatus(row.alarm_status, warnStatusOptions)">
					</el-table-column>
					<el-table-column width="110" align="center" prop="personname" label="巡防人姓名">
					</el-table-column>
					<el-table-column  width="220" align="center" prop="personcard" label="巡防人身份证">
					</el-table-column>
					<el-table-column width="160" align="center" prop="personphone" label="巡防人手机">
					</el-table-column>
					<el-table-column align="center" prop="housename" label="巡防地址">
					</el-table-column>
					<el-table-column align="center" prop="gridmanname" label="负责人">
					</el-table-column>
					<el-table-column align="center" prop="period_status" label="完成情况"
						:formatter="(row) => formatterStatus(row.period_status, periodStatusOptions)">
					</el-table-column>
					<el-table-column align="center" prop="totalcount" label="累计巡防次数" width="110">
					</el-table-column>
					<el-table-column align="center" prop="problemcount" label="发现问题数" width="95">
					</el-table-column>
					<el-table-column align="center" label="操作" width="200">
						<template slot-scope="scope">
							<el-button @click="viewClick(scope.row)" type="primary" size="mini">查看</el-button>
							<el-button @click="exportInfo(scope.row)" size="mini">导出</el-button>
						</template>
					</el-table-column>
				</el-table>

				<div class="pagebox">
					<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize"
						layout="total, sizes, prev, pager, next, jumper" :total="totalRow">
					</el-pagination>
				</div>

			</div>
		</div>


		<el-dialog append-to-body title="巡防详情" :close-on-click-modal="false" :visible.sync="detailVisible"
			width="1000px" @closed="detailTaskId = null">
			<detail-cpn :taskId="detailTaskId"></detail-cpn>
		</el-dialog>

	</div>
</template>

<script>
	import detailCpn from './detail'
	import { taskList, taskExport } from '@/api/sixtyPatrol'
	import {
		warnStatusOptions,
		periodStatusOptions,
	} from '../dict'

	export default {
		name: "",
		components: {
			detailCpn
		},
		props: {},
		data() {
			return {
				formInline: {
					gridman: '',
					houseaddress: '',
					warnstatus: '',
					periodstatus: '',
					personname: '',
					personcard: '',
				},
				tableData: [],
				currentPage: 1,
				pageSize: 10,
				totalRow: 0,
				warnStatusOptions,
				periodStatusOptions,
				taskPeriodData: {},
				detailVisible: false,
				detailTaskId: null,
				loading: false
			}
		},
		watch: {},
		created() {
		},
		mounted() {
			this.getList();
		},
		destroyed() {
		},
		computed: {},
		methods: {
			//巡防任务列表
			getList() {
				this.loading = true;
				taskList({
					gridman: this.formInline.gridman,
					houseaddress: this.formInline.houseaddress,
					alarmStatus: this.formInline.warnstatus,
					period_status: this.formInline.periodstatus,
					house_code: this.formInline.house_code,
					personname: this.formInline.personname,
					personcard: this.formInline.personcard,
					page: this.currentPage,
					pageSize: this.pageSize,
				}).then(res => {
					this.loading = false;
					if (res.result == 200) {
						this.tableData = res.detail.list;
						this.totalRow = res.detail.totalRow;
					} else {
						this.$message.warning(res.description)
					}
				})
			},
			
			handleSizeChange(val) {
				this.pageSize = val;
				this.getList();
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},
			//查询
			search() {
				this.currentPage = 1;
				this.getList();
			},
			//查看
			viewClick(row) {
				console.log(row)
				this.detailVisible = true;
				this.detailTaskId = row.id;
			},
			//导出
			exportInfo(row) {
				this.$confirm('是否确认导出该条任务的巡防流水?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					taskExport({
						id: row.id
					}).then(res => {
						this.$message.success('操作成功');
						const content = res
						const blob = new Blob([content]) //构造一个blob对象来处理数据
						var a = new Date();
						var year = a.getFullYear();
						var mon = a.getMonth() + 1;
						var month = mon > 9 ? mon : '0' + mon;
						var day = a.getDate() > 9 ? a.getDate() : '0' + a.getDate();
						var hour = a.getHours() > 9 ? a.getHours() : '0' + a.getHours();
						var min = a.getMinutes() > 9 ? a.getMinutes() : '0' + a.getMinutes();
						var s = a.getSeconds() > 9 ? a.getSeconds() : '0' + a.getSeconds();
						var time = year + '' + month + '' + day + '' + hour + '' + min + '' + s;
						const fileName = '巡防流水导出' + time + '.xls';
						const link = document.createElement('a') //创建a标签
						link.download = fileName //a标签添加属性
						link.style.display = 'none'
						link.href = URL.createObjectURL(blob)
						document.body.appendChild(link)
						link.click() //执行下载
						URL.revokeObjectURL(link.href) //释放url
						document.body.removeChild(link) //释放标签
					})
				}).catch(() => {

				});
			},
			//督促
			promptingClick(row) {

			},
			
			/**
			 * 字段格式化
			 * @param {Number|String} value
			 * @param {Array} list
			 * @return {String}
			 */
			formatterStatus(value, list) {
				if (value === "" || value == undefined) return "";
				const item = list.find((item) => item.value == value);
				return item ? item.label : "";
			}

		}
	}
</script>

<style lang="scss" scoped>
	.contbox {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		.leftcont {
			width: 100%;
		}

		

		.pagebox {
			padding: 20px 0;
		}
	}

	.formbox {
		width: 94%;
	}
</style>